<template>
  <div
    class="material-library box-shadow-light-grey padding-20 border-radius-6"
  >
    <div class="operation padding-bottom-20">
      <el-button @click="refreshData">刷新数据</el-button>
      <el-button class="" type="primary" @click="createSource"
        >新增素材</el-button
      >
      <el-button
        type="danger"
        plain
        :disabled="selectionList.length === 0"
        @click="delSelectSource"
        >批量删除</el-button
      >
      <el-input
        v-model="search"
        clearable
        placeholder="请输入素材信息"
        style="width: 240px; margin-right: 10px; margin-left: 10px"
        @clear="resetData('sourceName')"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
      <select-dept-user :params="params" :canDisabled="true" />
      <el-select
        v-model="searchStatus"
        style="margin-left: 10px; width: 100px"
        clearable
        @clear="resetData('searchStatus')"
        placeholder="素材类型"
      >
        <el-option label="图片" :value="0"></el-option>
        <el-option label="视频" :value="1"></el-option>
      </el-select>
      <el-select
        v-model="searchCId"
        @clear="resetData('searchCId')"
        clearable
        style="margin-left: 10px; width: 200px"
      >
        <el-option
          v-for="item in resourceCategoryList"
          :label="item.name"
          :value="item.id"
          :key="item.id"
        >
        </el-option>
      </el-select>
      <div class="choose-book-categoryId2">
        <el-select
          v-model="searchCategoryId"
          placeholder="书籍分类"
          style="width: 150px"
          filterable
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in categoryList"
            :key="item.id"
          >
          </el-option>
        </el-select>
        <div class="choose-book">
          <el-input
            v-model="searchBook"
            placeholder="书籍名称"
            style="width: 200px"
            clearable
            @clear="resetData('searchBookId')"
            @focus="handleFocusBookName"
          >
          </el-input>
          <div class="book-container" v-show="isShowFilterBook2">
            <div
              class="book-wrapper2"
              style="height: 260px"
              v-loading="searchBookLoading"
            >
              <ul ref="bookWrapper" class="ul-book-wrapper">
                <li v-for="item in filterBookList2" :key="item.id">
                  <label @click="chooseBookItem2(item)">
                    <span>{{ item.bookName }}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <el-date-picker
        v-model="timeRange"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="margin-right: 10px; width: 240px"
      >
      </el-date-picker>
      <el-button
        type="primary"
        @click="handleSearch"
        style="margin-right: 10px; margin-left: 0"
        class=""
        >搜索</el-button
      >
      <el-button
        type="info"
        style="margin-left: 0"
        @click="
          () => {
            resetData();
          }
        "
        class=""
        >重置</el-button
      >
    </div>
    <div class="custom-table" v-loading="loading">
      <el-table
        :data="dataList"
        style="width: 100%"
        height="calc(100vh - 290px)"
        ref="dataTable"
        @selection-change="
          (select) => {
            this.selectionList = select;
          }
        "
      >
        <el-table-column type="selection" width="55" fixed="left">
        </el-table-column>

        <el-table-column label="Id" prop="id" min-width="50"></el-table-column>
        <el-table-column label="书籍名称" min-width="120">
          <template slot-scope="scope">
            <div>
              {{ scope.row.book.bookName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="书籍分类" min-width="80">
          <template slot-scope="scope">
            <div v-if="'categoryName' in scope.row.book">
              {{ scope.row.book.categoryName }}
            </div>
            <div v-else>所有</div>
          </template>
        </el-table-column>
        <el-table-column label="用户名" min-width="100">
          <template slot-scope="scope">
            <div>
              {{ scope.row.adminUser.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="时间"
          prop="cdate"
          min-width="100"
        ></el-table-column>
        <el-table-column label="素材分类">
          <template slot-scope="scope">
            <div class="line-overflow">
              {{ getResourceName(scope.row.cId) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row.status === 0 ? "图片" : "视频" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="素材" prop="img" width="150">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              :title="`${scope.row.status === 0 ? '原图' : '原视频'}`"
              trigger="hover"
            >
              <template v-if="scope.row.status === 0">
                <div class="origin-img">
                  <img :src="scope.row.img" alt="原图" />
                </div>
                <div class="thumbnail-img" slot="reference">
                  <img :src="scope.row.img" />
                </div>
              </template>
              <template v-else>
                <div class="origin-img">
                  <video controls :src="scope.row.img" />
                </div>
                <div class="thumbnail-img" slot="reference">
                  <video :src="scope.row.img" />
                </div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="文案" min-width="200">
          <template slot-scope="scope">
            <el-popover placement="right" title="文案" trigger="hover">
              <div>
                {{ scope.row.content }}
              </div>
              <div class="line-overflow" slot="reference">
                {{ scope.row.content }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="想法" prop="idea" min-width="200">
          <template slot-scope="scope">
            <el-popover placement="right" title="想法" trigger="hover">
              <div>
                {{ scope.row.idea }}
              </div>
              <div class="line-overflow" slot="reference">
                {{ scope.row.idea }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="button-small"
              @click="editSource(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="button-small"
              @click="deleteSelectedPage(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-drawer
        direction="rtl"
        size="40%"
        :title="`${isEdit ? '编辑素材' : '新增素材'}`"
        :visible.sync="showDrawer"
        :before-close="handleClose"
      >
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="素材" prop="img">
            <el-button type="primary" @click="uploadImg">上传素材</el-button>
            <el-button type="danger" v-if="status === 1" @click="delVideo"
              >删除视频</el-button
            >
            <span style="color: #ccc; margin-left: 10px"
              >视频支持avi,mp4,mov格式</span
            >
            <input
              type="file"
              style="display: none"
              ref="imgUpload"
              @change="handleFileChange"
            />
            <div class="video-progress" v-if="showProcess">
              <el-progress
                :show-text="false"
                :percentage="videoPercent"
              ></el-progress>
            </div>
            <div class="source-img" v-if="status === 1 && ruleForm.img">
              <video width="200" height="160" controls :src="videoSrc"></video>
            </div>
            <div class="source-img" v-if="status === 0 && ruleForm.img">
              <img :src="ruleForm.img" alt="" />
              <div v-if="ruleForm.img" class="mask-hover">
                <i
                  class="iconfont icon-lajitong"
                  style="color: #fff"
                  @click="ruleForm.img = null"
                ></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="书籍" prop="bookId">
            <div class="choose-book-categoryId" v-loading="bookLoading">
              <el-select
                v-model="categoryId"
                placeholder="书籍分类"
                style="width: 150px"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in categoryList"
                  :key="item.id"
                >
                </el-option>
              </el-select>
              <div class="choose-book">
                <el-input
                  v-model="bookName"
                  placeholder="书籍名称"
                  style="width: 200px"
                >
                </el-input>
                <div class="book-container" v-show="isShowFilterBook">
                  <div class="book-wrapper" style="height: 260px">
                    <ul ref="bookWrapper" class="ul-book-wrapper">
                      <li v-for="item in filterBookList" :key="item.id">
                        <label @click="chooseBookItem(item)">
                          <span>{{ item.bookName }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="分类" prop="cId">
            <el-select
              v-model="ruleForm.cId"
              placeholder="请选择分类"
              filterable
            >
              <el-option
                v-for="item in resourceCategoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文案" prop="content">
            <el-input type="textarea" v-model="ruleForm.content"></el-input>
          </el-form-item>
          <el-form-item label="想法" prop="idea">
            <el-input type="textarea" v-model="ruleForm.idea"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确定</el-button
            >
            <el-button type="info" @click="handleCancel">取消</el-button>
          </el-form-item>
        </el-form>
      </el-drawer>
      <cropper-model
        :cropperVisible="cropperVisible"
        :imageUrl="needCropUrl"
        :trueWidth="trueWidth"
        :trueHeight="trueHeight"
        :fileName="fileName"
        :fixedBox="true"
        :needCropWidth.sync="needCropWidth"
        :needCropHeight.sync="needCropHeight"
        :myCropperWidth="myCropperWidth"
        :myCropperHeight="myCropperHeight"
        :cropTypeArr="cropTypeArr"
        :echoWHObj.sync="echoWHObj"
        @close="handleCropperClose"
        @cropSuccess="cropSuccess"
      ></cropper-model>
    </div>
  </div>
</template>
<script>
import {
  getWeChatSourceList,
  addWeChatSource,
  editWeChatSource,
  delWeChatSource,
  compressUpload,
} from "@/api/novelPut.js";
import BScroll from "better-scroll";
import { getBookList } from "@/api/account";
import { getClassifyList } from "@/api/classify";
import SelectDeptUser from "@/components/SelectDeptUser";
import cropperModel from "./components/cropper-model.vue";
import { mapGetters, mapState, mapMutations } from "vuex";
export default {
  name: "materialLibrary",
  data() {
    return {
      page: 1,
      pageSize: 10,
      status: 0, // 0：图片 1：视频
      selected: null,
      search: "",
      dataList: [],
      // isShowDeptUser: false,
      params: {
        deptId: null,
        adminUserId: null,
      },
      showProcess: false, // 是否显示进度条
      videoSrc: "",
      total: 0,
      searchBook: null,
      searchBookId: null,
      searchCategoryId: null,
      loading: false,
      uploadLoading: false,
      bookLoading: false,
      isEdit: false,
      userId: null,
      timeRange: [],
      selectionList: [],
      showDrawer: false,
      sourceId: null,
      adminUserId: null, // 编辑用户id
      bookId: null, // 书籍id
      bookName: "", // 书籍名称
      searchOptions: [],
      options: [],
      isShowFilterBook: false,
      isShowFilterBook2: false,
      filterBookList: [],
      filterBookList2: [],
      searchBookLoading: false,
      isCanHidden: false,
      isCanHidden2: false,
      cropperVisible: false,
      categoryId: 0, // 书籍分类id
      categoryList: [],
      searchStatus: null,
      searchCId: null,
      resourceCategoryList: [], // 素材列表
      ruleForm: {
        // cdate: new Date(),
        cId: 0,
        img: null,
        // img:'https://h5.file.yayawx.cn/novel_put/2021020788465.jpeg',
        content: "",
        idea: "",
        bookId: null,
      },
      needCropUrl: "",
      trueWidth: 0,
      trueHeight: 0,
      fileName: null,
      needCropWidth: 0,
      needCropHeight: 0,
      myCropperHeight: 0,
      myCropperWidth: 0,
      cropTypeArr: [],
      echoWHObj: {},
      isFistInput: true,
      isFlag: false, // 用于新增素材导致书籍Id异常显示列表而设置的标识
      rules: {
        cId: [
          {
            required: true,
            message: "请选择素材分类!",
            trigger: "blur",
          },
        ],
        img: [
          {
            required: true,
            message: "请上传素材!",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入文案",
            trigger: "blur",
          },
        ],
        idea: [
          {
            required: true,
            message: "请输入想法",
            trigger: "blur",
          },
        ],
        bookId: [
          {
            required: true,
            message: "请选择书籍Id",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userList"]),
    ...mapState(["weChatSourceTemporaryData"]),
    filUser: function () {
      const tmpUser = this.$store.state.userList.filter((item) => {
        return item.isPut === 1;
      });
      return tmpUser;
    },
    videoPercent() {
      return this.$store.state.uploadPercent;
    },
  },
  watch: {
    videoPercent: {
      handler(newV) {
        this.showProcess = newV !== 100;
      },
    },
    searchCategoryId: {
      handler(newV) {
        this.searchBookId = null;
        this.searchBook = null;
        getBookList({
          pageSize: 99999,
          categoryId: newV === 0 ? null : newV,
        }).then((res) => {
          this.searchOptions = res.list;
        });
      },
    },
    categoryId: {
      handler(newV) {
        getBookList({
          pageSize: 99999,
          categoryId: newV === 0 ? null : newV,
        }).then((res) => {
          this.options = res.list;
        });
      },
    },
    bookName: {
      handler(newV, oldV) {
        if (newV) {
          // !回退操作
          if (newV && oldV && newV.length < oldV.length) {
            this.isCanHidden = false;
          }
          this.filterBookList = this.options.filter((item) => {
            return item.bookName.includes(newV);
          });
          if (
            this.filterBookList.length > 0 &&
            !this.isCanHidden &&
            !this.isFlag
          ) {
            this.isShowFilterBook = true;
          } else {
            this.isShowFilterBook = false;
          }
          // isFlag 只起一次作用
          this.isFlag = false;
          this.initScroll();
        } else {
          this.isCanHidden = false;
          this.isShowFilterBook = false;
          this.filterBookList = [];
        }
      },
    },
    searchBook: {
      handler(newV, oldV) {
        if (newV) {
          // !回退操作
          if (newV && oldV && newV.length < oldV.length) {
            this.isCanHidden2 = false;
          }
          this.filterBookList2 = this.searchOptions.filter((item) => {
            return item.bookName.includes(newV);
          });
          if (this.filterBookList2.length > 0 && !this.isCanHidden2) {
            this.isShowFilterBook2 = true;
          } else {
            this.isShowFilterBook2 = false;
          }
          this.initScroll2();
        } else {
          this.isCanHidden2 = false;
          this.isShowFilterBook2 = false;
          this.filterBookList2 = [];
        }
      },
    },
    isCanHidden: {
      handler(newV) {
        if (this.filterBookList.length > 0 && !newV) {
          this.isShowFilterBook = true;
        } else {
          this.isShowFilterBook = false;
        }
      },
    },
    isCanHidden2: {
      handler(newV) {
        if (this.filterBookList2.length > 0 && !newV) {
          this.isShowFilterBook2 = true;
        } else {
          this.isShowFilterBook2 = false;
        }
      },
    },
    showDrawer: {
      handler(newV) {
        if (newV) {
          this.$nextTick(() => {
            this.initBookList();
          });
        }
      },
    },
    ruleForm: {
      handler(newV) {
        this.setWeChatSourceTemporaryData(JSON.parse(JSON.stringify(newV)));
      },
      deep: true,
    },
  },
  mounted() {
    this.initBook();
    this.initResoure();
    this.initData();
    // document.querySelector('.material-library').addEventListener('click',this.)
    document.querySelector(".material-library").onclick = (e) => {
      if (!document.querySelector(".book-container").contains(e.target)) {
        this.isShowFilterBook2 = false;
      }
    };
  },
  methods: {
    ...mapMutations(["setWeChatSourceTemporaryData", "setUploadPercent"]),
    initResoure() {
      getClassifyList({ type: 5 }).then((res) => {
        this.resourceCategoryList = JSON.parse(JSON.stringify(res));
        this.resourceCategoryList.unshift({
          id: 0,
          name: "所有分类",
          type: 5,
        });
      });
    },
    initBookList() {
      this.bookLoading = true;
      getClassifyList({
        type: 3,
      }).then((res) => {
        this.categoryList = JSON.parse(JSON.stringify(res));
        this.categoryList.unshift({
          id: 0,
          name: "所有分类",
          status: 1,
          type: 3,
        });
        getBookList({
          pageSize: 99999,
          categoryId: this.categoryId === 0 ? null : this.categoryId,
        })
          .then((res) => {
            this.options = res.list;
            if (this.isEdit || this.ruleForm.bookId) {
              const target = this.options.find((item) => {
                return item.id === this.bookId;
              });
              this.bookName = target.bookName;
            }
          })
          .finally(() => {
            this.bookLoading = false;
          });
      });
    },
    initBook(isPage = false) {
      this.searchBookLoading = true;
      getClassifyList({
        type: 3,
      }).then((res) => {
        this.categoryList = JSON.parse(JSON.stringify(res));
        this.categoryList.unshift({
          id: 0,
          name: "所有分类",
          status: 1,
          type: 3,
        });
        getBookList({
          pageSize: isPage ? 30 : 99999,
          categoryId: this.searchCategoryId,
        })
          .then((res) => {
            this.searchOptions = res.list;
            if (isPage) {
              this.filterBookList2 = JSON.parse(
                JSON.stringify(this.searchOptions)
              );
            }
          })
          .finally(() => {
            this.searchBookLoading = false;
          });
      });
    },
    initScroll() {
      this.$nextTick(() => {
        const wrapper = document.querySelector(".book-wrapper");
        if (!this.bookScroll) {
          this.bookScroll = new BScroll(wrapper, {
            scrollY: true,
            click: true,
            scrollbar: {
              fade: false,
              interactive: true,
            },
            bounce: {
              top: true,
              bottom: true,
              left: true,
              right: true,
            },
            bounceTime: "2000",
            mouseWheel: true,
          });
        }
      });
    },
    initScroll2() {
      this.$nextTick(() => {
        const wrapper = document.querySelector(".book-wrapper2");
        if (!this.bookScroll2) {
          this.bookScroll2 = new BScroll(wrapper, {
            scrollY: true,
            click: true,
            scrollbar: {
              fade: false,
              interactive: true,
            },
            bounce: {
              top: true,
              bottom: true,
              left: true,
              right: true,
            },
            bounceTime: "2000",
            mouseWheel: true,
          });
        }
      });
    },
    initData() {
      this.loading = true;
      getWeChatSourceList({
        page: this.page,
        pageSize: this.pageSize,
        minDate: this.timeRange ? this.timeRange[0] : null,
        maxDate: this.timeRange ? this.timeRange[1] : null,
        search: this.search,
        status: this.searchStatus,
        cId: this.searchCId,
        deptId: this.params.deptId ? this.params.deptId : null,
        userId: this.params.adminUserId ? this.params.adminUserId : null,
        bookId: this.searchBookId,
        categoryId: this.searchCategoryId ? this.searchCategoryId : null,
      })
        .then((res) => {
          this.total = res.total;
          this.dataList = res.list;
          this.$nextTick(() => {
            this.$refs.dataTable.doLayout();
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createSource() {
      this.isEdit = false;
      // this.isCanHidden = false;
      const {
        img,
        content,
        idea,
        bookId,
        cId,
      } = this.weChatSourceTemporaryData;
      this.bookId = bookId || null;
      this.ruleForm.img = img || null;
      this.ruleForm.content = content || null;
      this.ruleForm.idea = idea || null;
      this.ruleForm.bookId = bookId || null;
      this.ruleForm.cId = cId || 0;
      this.isFlag = !!this.weChatSourceTemporaryData.bookId;
      this.showDrawer = true;
      this.showProcess = false;
    },
    handlePageChange(page) {
      this.page = page;
      this.initData();
    },
    refreshData() {
      this.initData();
    },
    handleSearch() {
      this.initData();
    },
    editSource(val) {
      //   this.bookId = val.book.categoryId ?  val.book.categoryId : 0

      if (val.status === 1) {
        this.videoSrc = val.img;
      }
      this.status = val.status;
      this.showProcess = false;
      this.isCanHidden = true;
      this.categoryId = val.book.categoryId ? val.book.categoryId : 0;
      this.bookId = val.bookId;
      this.isEdit = true;
      this.ruleForm = {
        cId: val.cId,
        img: val.img,
        content: val.content,
        idea: val.idea,
        bookId: this.bookId,
      };
      this.sourceId = val.id;
      this.adminUserId = val.adminUserId;
      this.$nextTick(() => {
        this.showDrawer = true;
      });
    },
    delSelectSource() {
      const ids = this.selectionList.map((item) => item.id).join(",");
      this.deleteSelectedPage(ids);
    },
    deleteSelectedPage(ids) {
      this.$confirm("此操作将永久删除该素材, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delWeChatSource(ids).then(() => {
            this.$message({
              showClose: true,
              message: "删除成功!",
              type: "success",
            });
            this.initData();
          });
        })
        .catch(() => {});
    },
    submitForm(refName) {
      if (this.videoSrc) {
        this.ruleForm.img = this.videoSrc;
      }
      this.$refs[refName].validate((valid) => {
        if (!valid) {
          this.$message({
            showClose: true,
            message: "信息配置有误!",
            type: "error",
          });
        } else {
          // 新增
          if (!this.bookId) {
            this.showMessage("请选择书籍!", "error");
            return;
          }
          if (!this.isEdit) {
            addWeChatSource({
              cId: this.ruleForm.cId,
              img: this.ruleForm.img,
              // img:'https://h5.file.yayawx.cn/novel_put/2021020788465.jpeg',
              content: this.ruleForm.content,
              idea: this.ruleForm.idea,
              bookId: this.ruleForm.bookId,
              status: this.status,
            })
              .then(() => {
                this.showMessage("新增成功!", "success");
                // 如果用户点击了确定 则重置临时数据
                this.setWeChatSourceTemporaryData({});
              })
              .finally(() => {
                this.isEdit = false;
                this.handleCancel();
                this.initData();
              });
            // 更新
          } else {
            editWeChatSource(this.sourceId, {
              // cdate: this.ruleForm.cdate,
              cId: this.ruleForm.cId,
              img: this.ruleForm.img,
              content: this.ruleForm.content,
              idea: this.ruleForm.idea,
              adminUserId: this.adminUserId,
              bookId: this.ruleForm.bookId,
              status: this.status,
            })
              .then(() => {
                this.showMessage("编辑成功!", "success");
              })
              .finally(() => {
                this.isEdit = false;
                this.handleCancel();
                this.initData();
              });
          }
        }
      });
    },
    uploadImg() {
      this.$refs.imgUpload.click();
    },
    async handleFileChange(e) {
      this.uploadLoading = true;
      const targetFile = e.target ? e.target.files[0] : e.srcElement.files[0];
      //   if (targetFile.size > 3000 * 1024) {
      //     this.showMessage("上传的图片太大，请重新上传!", "error");
      //     this.$refs.imgUpload.value = "";
      //     return;
      //   }

      if (["video/avi", "video/mp4", "video/mov"].includes(targetFile.type)) {
        this.status = 1;
        const res = await compressUpload({ file: targetFile });
        this.videoSrc = res;
        this.ruleForm.img = res;
        this.$refs.imgUpload.value = "";
      } else {
        this.videoSrc = "";
        this.status = 0;
        const fr = new FileReader();
        fr.readAsDataURL(targetFile);
        fr.onload = (event) => {
          const image = new Image();
          image.src = event.currentTarget.result;
          // const src = image.src
          image.onload = () => {
            //   const { width, height } = image
            compressUpload({ file: targetFile })
              .then((res) => {
                this.ruleForm.img = res;
                this.$refs.imgUpload.value = "";
              })
              .finally(() => {
                this.uploadLoading = false;
              });
            //   this.cropTypeArr = [
            //     {
            //       width: 800,
            //       height: 800
            //     },
            //     {
            //       width: 800,
            //       height: 640
            //     },
            //     {
            //       width: 800,
            //       height: 450
            //     },
            //     {
            //       width: 640,
            //       height: 800
            //     }
            //   ];
            //   if (width < 640 || height < 450) {
            //     this.showMessage("上传的图片尺寸不符合要求，请重新上传!", "error");
            //     this.$refs.imgUpload.value = "";
            //     this.cropTypeArr = [];
            //     return;
            //   }
            //   if (width > 800 && height < 450) {
            //     this.showMessage("上传的图片尺寸不符合要求，请重新上传!", "error");
            //     this.$refs.imgUpload.value = "";
            //     this.cropTypeArr = [];
            //     return;
            //   }
            //   // 裁剪800 * 640
            //   if (height > 640 && height < 800 && width >= 800) {
            //     this.cropTypeArr = [
            //       {
            //         width: 800,
            //         height: 640
            //       },
            //       {
            //         width: 800,
            //         height: 450
            //       }
            //     ];
            //   }
            //   // 裁剪800 * 450
            //   if (height > 450 && height < 640 && width >= 800) {
            //     this.cropTypeArr = [
            //       {
            //         width: 800,
            //         height: 450
            //       }
            //     ];
            //   }

            //   // 裁剪 640*800
            //   if (width > 640 && width < 800 && height < 800) {
            //     this.showErrorMsg("上传的图片尺寸不符合要求，请重新上传!");
            //     this.$refs.uploadTopImage.value = "";
            //     this.cropTypeArr = [];
            //     return;
            //   }
            //   if (width > 640 && width < 800 && height >= 800) {
            //     this.cropTypeArr = [
            //       {
            //         width: 640,
            //         height: 800
            //       }
            //     ];
            //   }

            //   if (width === 800 && height === 800) {
            //     this.cropTypeArr = [
            //       {
            //         width: 800,
            //         height: 800
            //       },
            //       {
            //         width: 800,
            //         height: 640
            //       },
            //       {
            //         width: 800,
            //         height: 450
            //       },
            //       {
            //         width: 640,
            //         height: 800
            //       }
            //     ];
            //   }
            //   if (width === 800 && height === 640) {
            //     this.cropTypeArr = [
            //       {
            //         width: 800,
            //         height: 640
            //       },
            //       {
            //         width: 800,
            //         height: 450
            //       }
            //     ];
            //   }

            //   if (width === 800 && height === 450) {
            //     this.cropTypeArr = [
            //       {
            //         width: 800,
            //         height: 450
            //       }
            //     ];
            //   }

            //   if (width === 640 && height === 800) {
            //     this.cropTypeArr = [
            //       {
            //         width: 640,
            //         height: 800
            //       }
            //     ];
            //   }

            //   this.trueWidth = width;
            //   this.trueHeight = height;
            //   this.needCropUrl = src;
            //   this.needCropWidth = this.cropTypeArr[0].width / 2;
            //   this.needCropHeight = this.cropTypeArr[0].height / 2;
            //   this.myCropperWidth = 800;
            //   this.myCropperHeight = 800;
            //   this.fileName = targetFile.name;
            //   this.cropperVisible = true;
          };
        };
      }
    },
    handleCropperClose() {
      this.$refs.imgUpload.value = "";
      this.needCropHeight = 0;
      this.needCropWidth = 0;
      this.cropperVisible = false;
      this.cropTypeArr = [];
    },
    cropSuccess(file) {
      compressUpload({ file })
        .then((res) => {
          this.ruleForm.img = res;
          this.$refs.imgUpload.value = "";
        })
        .finally(() => {
          this.uploadLoading = false;
        });
    },
    handleClose() {
      this.$confirm("确定取消吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.showDrawer = false;
          // 如果是编辑 则需要取消全部内容
          if (this.isEdit) {
            this.setWeChatSourceTemporaryData({});
            this.handleCancel();
          }
        })
        .catch(() => {});
    },
    getResourceName(cId) {
      let target = this.resourceCategoryList.find((item) => item.id === cId)
      return target ? target.name : '';
    },
    handleCancel() {
      this.bookName = null;
      this.bookId = null;
      this.isCanHidden = true;
      this.isShowFilterBook = false;
      this.showDrawer = false;
      this.videoSrc = "";
      this.status = 0;
      this.showProcess = false;
      this.categoryId = 0;
      this.ruleForm = {
        // cdate: new Date(),
        cId: null,
        img: null,
        content: "",
        idea: "",
        bookId: null,
      };
      this.setUploadPercent(0);
      this.setWeChatSourceTemporaryData({});
    },
    chooseBookItem(bookItem) {
      this.bookName = bookItem.bookName;
      this.bookId = bookItem.id;
      this.ruleForm.bookId = this.bookId;
      this.isCanHidden = true;
    },
    chooseBookItem2(bookItem) {
      this.searchBook = bookItem.bookName;
      this.searchBookId = bookItem.id;
      this.isCanHidden2 = true;
    },
    showMessage(message, type = "error") {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },
    delVideo() {
      this.videoSrc = "";
      this.$refs.imgUpload.value = "";
      this.ruleForm.img = "";
    },
    handleFocusBookName() {
      //   if(!this.searchBook){
      //     this.isShowFilterBook2 = true;
      //     this.initBook(true)
      //     this.initScroll()
      //   }
    },
    resetData(property = "all") {
      if (property === "userId") {
        this.userId = null;
      }
      if (property === "sourceName") {
        this.search = "";
      }
      if (property === "searchBookId") {
        this.searchBookId = null;
      }
      if (property === "searchCategoryId") {
        this.searchCategoryId = null;
      }
      if (property === "searchStatus") {
        this.searchStatus = null;
      }
      if (property === "searchCId") {
        this.searchCId = null;
      }

      if (property === "all") {
        this.userId = null;
        this.search = "";
        this.timeRange = [];
        this.searchStatus = null;
        this.searchBookId = null;
        this.searchBook = null;
        this.searchCategoryId = null;
        this.params.adminUserId = null;
        this.params.deptId = null;
        this.searchCId = null;
      }
      this.page = 1;
      this.initData();
    },
  },
  beforeDestroy() {
    document.querySelector(".material-library").onclick = null;
  },
  components: {
    cropperModel,
    SelectDeptUser,
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-table {
  .is-leaf {
    border-bottom: 0;
  }
}

/deep/ .el-drawer {
  .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  .el-drawer__body {
    box-sizing: border-box;
    padding: 20px;
  }
}
.operation {
  line-height: 40px;
}
.choose-book-categoryId {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .choose-book {
    position: relative;
    margin-left: 10px;
    font-size: 14px;
    .book-container {
      position: absolute;
      background: #fff;
      z-index: 1000;
      width: 300px;
      top: 40px;
      > p {
        padding: 6px 10px;
      }
      .book-wrapper {
        overflow: hidden;
        border: 1px solid #ccc;
        border-radius: 4px;
        .ul-book-wrapper {
          li {
            label {
              box-sizing: border-box;
              padding: 6px 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #24262e;
            }
            &:hover {
              background: #ebedf0;
            }
          }
        }
      }
    }
  }
}
.choose-book-categoryId2 {
  display: inline-block;
  margin: 0 10px;
  .choose-book {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    font-size: 14px;
    .book-container {
      position: absolute;
      background: #fff;
      z-index: 1000;
      width: 200px;
      top: 40px;
      > p {
        padding: 6px 10px;
      }
      .book-wrapper2 {
        overflow: hidden;
        border: 1px solid #ccc;
        border-radius: 4px;
        .ul-book-wrapper {
          li {
            label {
              box-sizing: border-box;
              padding: 12px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #24262e;
            }
            &:hover {
              background: #ebedf0;
            }
          }
        }
      }
    }
  }
}

.source-img {
  margin-top: 20px;
  width: 200px;
  height: 160px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .mask-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    display: none;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  &:hover {
    .mask-hover {
      display: block;
    }
  }
}
.origin-img {
  img {
    width: 400px;
  }
  video {
    width: 400px;
  }
}

.thumbnail-img {
  width: 64px;
  height: 36px;
  img {
    width: 100%;
    height: 100%;
  }
  video {
    width: 100%;
    height: 100%;
  }
}
.material-library {
  background: #fff;
  width: calc(100%) !important;
  .custom-table {
  }
}

.video-progress {
  margin-top: 20px;
}
</style>
